import cookie from 'utils/cookie';
import { COOKIE_KEYS } from 'consts/cookieKeys';

// TODO /lpVariant cleanup
const captureVariant = (variantCode: string) => {
  if (typeof window === 'undefined' || cookie.has(COOKIE_KEYS.lpVariant)) {
    return;
  }

  cookie.set(COOKIE_KEYS.lpVariant, variantCode);
};

export default captureVariant;
