import React from 'react';
import { ThumbsDownIcon, ThumbsUpIcon } from 'radiance-ui/lib/icons';

import {
  ChartContainer,
  ListContainer,
  Title,
  List,
  ListItem,
  ItemText,
  IconWrapper,
} from './Chart.style';

export type ChartProps = {
  data: {
    positive: {
      title: string;
      list: string[];
    };
    negative: {
      title: string;
      list: string[];
    };
  };
};

export const Chart = ({ data: { positive, negative } }: ChartProps) => (
  <ChartContainer>
    <ListContainer>
      <Title>{negative.title}</Title>
      <List>
        {negative.list.map((listItem) => (
          <ListItem key={listItem}>
            <IconWrapper>
              <ThumbsDownIcon />
            </IconWrapper>
            <ItemText>{listItem}</ItemText>
          </ListItem>
        ))}
      </List>
    </ListContainer>
    <ListContainer isPositive>
      <Title>{positive.title}</Title>
      <List>
        {positive.list.map((listItem) => (
          <ListItem key={listItem}>
            <IconWrapper>
              <ThumbsUpIcon />
            </IconWrapper>
            <ItemText>{listItem}</ItemText>
          </ListItem>
        ))}
      </List>
    </ListContainer>
  </ChartContainer>
);
