import {
  COMPLETE_SET_HEADER_TEEN,
  CTA_UNLOCK_YOUR_TEENS_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { CompleteSetProps } from '..';

export const COMPLETE_SET_MOMS_OF_TEENS_CONTENT: CompleteSetProps = {
  header: COMPLETE_SET_HEADER_TEEN,
  description:
    'Dermatologist-designed (and pH-balanced) cleanser and moisturizer are included. They pair perfectly with our Custom Formula.',
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_TEENS_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
