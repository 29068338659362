import {
  CTA_UNLOCK_YOUR_TEENS_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import type { ProvidersOldProps } from '..';

export const PROVIDERS_OLD_MOMS_OF_TEENS_CONTENT: ProvidersOldProps = {
  title: 'Real dermatology providers take your teen’s skin personally',
  text: 'They’ll create a formula customized for your teen and provide guidance throughout their skincare journey.',
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_TEENS_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
