import {
  COMPLETE_SET_HEADER_ONE_TRIAL,
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { CompleteSetProps } from '..';

export const COMPLETE_SET_MALE_CONTENT: CompleteSetProps = {
  header: COMPLETE_SET_HEADER_ONE_TRIAL,
  description:
    'Need a cleanser and moisturizer? Ours are pH‑balanced, dermatologist‑designed—and made to pair perfectly with your custom formula.',
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
