import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { QuizProps } from '..';

export const QUIZ_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT: QuizProps = {
  header: 'How many common misconceptions can you spot?',
  cards: [
    {
      question: 'You’ll know right away if your new treatment is working.',
      correctAnswer: false,
      response: (
        <React.Fragment>
          It often takes a few weeks to months before you see the full effect of
          the medication. Patience is&nbsp;key!
        </React.Fragment>
      ),
      image: (
        <StaticImage
          src="../images/question1@2x.jpg"
          alt="Teen applying Curology onto their hand"
        />
      ),
    },
    {
      question: 'Washing your face should be limited to 1 or 2x a day.',
      correctAnswer: true,
      response: (
        <React.Fragment>
          Overwashing can be a source of irritation. We recommend using our
          gentle cleanser twice daily for best&nbsp;results.
        </React.Fragment>
      ),
      image: (
        <StaticImage
          src="../images/question2@2x.jpg"
          alt="Hands washing with a bottle of Curology cleanser in the sink"
        />
      ),
    },
    {
      question:
        'If breakouts come back after stopping treatment, it means it didn’t work.',
      correctAnswer: false,
      response: (
        <React.Fragment>
          The goal is to “control, not cure” breakouts. It’s important to
          maintain a simple, effective routine long&#8209;term.
        </React.Fragment>
      ),
      image: (
        <StaticImage src="../images/question3@2x.jpg" alt="Smiling teen face" />
      ),
    },
    {
      question: 'Your skin can become immune to certain ingredients.',
      correctAnswer: false,
      response: (
        <React.Fragment>
          It’s a common misconception that your body can “get used to” skincare
          ingredients over&nbsp;time.
        </React.Fragment>
      ),
      image: (
        <StaticImage
          src="../images/question4@2x.jpg"
          alt="A smear of an ingredient"
        />
      ),
    },
    {
      question: 'The more expensive a skincare product, the better.',
      correctAnswer: false,
      response: (
        <React.Fragment>
          It won’t necessarily be made with quality ingredients. Stick with
          recommendations from our&nbsp;providers.
        </React.Fragment>
      ),
      image: (
        <StaticImage
          src="../images/question5@2x.jpg"
          alt="Smiling teen applying Curology to their face"
        />
      ),
    },
    {
      question: 'Quiz complete! Ready to see how Curology can help your teen?',
      correctAnswer: false,
      response: '',
      image: <StaticImage src="../images/end@2x.jpg" alt="Curology bottle" />,
    },
  ],
};
