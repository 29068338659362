import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import throttle from 'lodash.throttle';
import { ArrowLeftIcon, ArrowRightIcon } from 'radiance-ui/lib/icons';
import { RoundButton } from 'radiance-ui';
import smoothscroll from 'smoothscroll-polyfill';

import { mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';

import {
  arrowButtonStyle,
  arrowMobile,
  CardSlider,
  CardSliderContainer,
  HeaderCard,
  headerText,
  MainBox,
  sectionBox,
  SliderNavigation,
} from './Quiz.style';
import { QuizCard } from './components/QuizCard';

export type QuizCardType = {
  question: string;
  response: React.ReactNode;
  correctAnswer: boolean;
  image: React.ReactNode;
};

export type QuizProps = {
  header: string;
  cards: QuizCardType[];
};

const moduleName = 'quiz';

export const Quiz = ({ cards, header }: QuizProps) => {
  const [isMobileArrowVisbile, setIsMobileArrowVisbile] = useState(false);
  const [isScrollStart, setIsScrollStart] = useState(true);
  const [isScrollEnd, setIsScrollEnd] = useState(false);

  const theme = useTheme();
  const navRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleNavigation = (direction: string) => {
    setIsMobileArrowVisbile(false);
    navRef.current.scrollBy({
      left: direction === 'right' ? 324 : -324,
      behavior: 'smooth',
    });
    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      'Module Name': `Quiz`,
      'Module Element Section': 'Navigation',
      'Module Element Interaction Type': 'Left or Right',
      'Element Content': direction,
    });
  };

  const handleOnScroll = useCallback(
    throttle(() => {
      setIsMobileArrowVisbile(false);
      setIsScrollStart(navRef.current.scrollLeft < 50);
      setIsScrollEnd(
        navRef.current.scrollLeft + navRef.current.offsetWidth + 40 >
          navRef.current.scrollWidth,
      );
    }, 100),
    [navRef.current],
  );

  const handleChildChanged = () => {
    setIsMobileArrowVisbile(true);
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <section css={sectionBox}>
      <MainBox data-module={moduleName}>
        <h2 css={[headerText(theme), mobileOnly()]}>{header}</h2>
        <CardSliderContainer>
          <CardSlider ref={navRef} onScroll={handleOnScroll}>
            <HeaderCard>
              <h2 css={headerText}>{header}</h2>
            </HeaderCard>
            {cards.map((card, index) => {
              const isEndCard = cards.length === index + 1;
              return (
                <QuizCard
                  key={card.question}
                  card={card}
                  isEndCard={isEndCard}
                  onChange={handleChildChanged}
                />
              );
            })}
          </CardSlider>
          <RoundButton
            buttonType="primary"
            icon={<ArrowRightIcon />}
            onClick={() => handleNavigation('right')}
            css={[
              arrowButtonStyle(theme),
              mobileOnly(),
              arrowMobile(theme, isMobileArrowVisbile),
            ]}
            type="button"
            disabled={isScrollEnd}
          />
        </CardSliderContainer>
        <SliderNavigation>
          <RoundButton
            icon={<ArrowLeftIcon />}
            buttonType="primary"
            onClick={() => handleNavigation('left')}
            css={arrowButtonStyle}
            type="button"
            disabled={isScrollStart}
          />
          <RoundButton
            buttonType="primary"
            icon={<ArrowRightIcon />}
            onClick={() => handleNavigation('right')}
            css={arrowButtonStyle}
            type="button"
            disabled={isScrollEnd}
          />
        </SliderNavigation>
      </MainBox>
    </section>
  );
};
