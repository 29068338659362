import React from 'react';

import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';
import {
  OuterContainer,
  InnerContainer,
  header,
  CtaContainer,
  ProductsContainer,
  ProductContainer,
  ProductDetails,
  productName,
  productPrice,
  productDescription,
} from './Products.style';

export type ProductsProps = {
  ctaButton: CtaButtonProps;
  title: string;
  products: {
    name: string;
    price: string;
    description: string;
    image: React.ReactNode;
  }[];
};

const moduleName = 'products';

export const Products = ({ ctaButton, title, products }: ProductsProps) => (
  <OuterContainer data-module={moduleName}>
    <InnerContainer>
      <h3 css={header}>{title}</h3>
      <ProductsContainer>
        {products.map((product) => (
          <ProductContainer key={product.name}>
            {product.image}
            <ProductDetails>
              <strong css={productName}>{product.name}</strong>
              <span css={productPrice}>{product.price}</span>
            </ProductDetails>
            <span css={productDescription}>{product.description}</span>
          </ProductContainer>
        ))}
      </ProductsContainer>
      <CtaContainer>
        <CtaButton {...ctaButton} module={moduleName} />
      </CtaContainer>
    </InnerContainer>
  </OuterContainer>
);
