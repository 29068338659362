import React from 'react';
import { useTheme } from '@emotion/react';

import { MOMS_OF_TEENS_MARKETING_SAFE_CODE } from 'consts/landingVariants';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import {
  SEO,
  SEO_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import {
  LandingHeaderMomsOfTeens,
  LANDING_HEADER_MOMS_OF_TEENS_CONTENT,
} from 'components/modules/LandingHeaderMomsOfTeens';
import {
  Welcome,
  WELCOME_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/Welcome';
import {
  Ratings,
  RATINGS_MOMS_OF_TEENS_CONTENT,
} from 'components/modules/Ratings';
import {
  Quiz,
  QUIZ_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/Quiz';
import {
  ProvidersOld,
  PROVIDERS_OLD_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/ProvidersOld';
import {
  ComparisonChart,
  COMPARISON_CHART_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/ComparisonChart';
import {
  CompleteSet,
  COMPLETE_SET_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/CompleteSet';
import {
  Products,
  PRODUCTS_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/Products';
import {
  Faq,
  FAQ_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT,
} from 'components/modules/Faq';
import { Footer } from 'components/global/Footer';

const MomsOfTeensMarketingSafeLandingVariant = ({
  freeTrial,
}: {
  freeTrial: boolean;
}) => {
  const theme = useTheme();
  return (
    <LandingPageWrapper variantCode={MOMS_OF_TEENS_MARKETING_SAFE_CODE}>
      <SEO {...SEO_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <Nav freeTrial={freeTrial} isMarketingSafe />
      <LandingHeaderMomsOfTeens {...LANDING_HEADER_MOMS_OF_TEENS_CONTENT} />
      <Welcome {...WELCOME_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <Ratings {...RATINGS_MOMS_OF_TEENS_CONTENT} />
      <Quiz {...QUIZ_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <ProvidersOld {...PROVIDERS_OLD_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <ComparisonChart
        {...COMPARISON_CHART_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT}
        backgroundColor={theme.COLORS.white}
      />
      <CompleteSet {...COMPLETE_SET_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <Products {...PRODUCTS_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <Faq {...FAQ_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT} />
      <Footer isMarketingSafe />
    </LandingPageWrapper>
  );
};

export default MomsOfTeensMarketingSafeLandingVariant;
