import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';

const setupModuleObserver = (containerElement?: string) => {
  if (window.IntersectionObserver) {
    const modulesQueryString = containerElement
      ? `${containerElement} [data-module]`
      : '[data-module]';

    const allModules = document.querySelectorAll(modulesQueryString);

    const createModuleObserver = (position: 'top' | 'middle') => {
      return new window.IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              trackEvent(EVENTS.moduleViewed, {
                path: window.location.pathname,
                module: entry.target.getAttribute('data-module'),
                order: Array.from(allModules).indexOf(entry.target),
                position,
              });
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: position === 'top' ? 0 : 0.3 },
      );
    };

    const moduleTopObserver = createModuleObserver('top');
    const moduleMiddleObserver = createModuleObserver('middle');

    allModules.forEach((module) => {
      moduleTopObserver.observe(module);
      moduleMiddleObserver.observe(module);
    });
  }
};

export default setupModuleObserver;
