export const ANTI_AGING = 'anti-aging';
export const ANTI_AGING_MARKETING_SAFE = 'anti-aging-marketing-safe';
export const CORE = 'core';
export const CORE_MARKETING_SAFE = 'core-marketing-safe';
export const DARK_SPOT_FORMULA = 'dark-spot-formula';
export const DARK_SPOT_FORMULA_MARKETING_SAFE =
  'dark-spot-formula-marketing-safe';
export const HOME = 'home';
export const MALE = 'male';
export const MALE_MARKETING_SAFE = 'male-marketing-safe';
export const MOMS_OF_TEENS = 'moms-of-teens';
export const MOMS_OF_TEENS_MARKETING_SAFE = 'moms-of-teens-marketing-safe';
export const NEUTRAL = 'neutral';
export const NEUTRAL_MARKETING_SAFE = 'neutral-marketing-safe';
export const SKINCARE_FOR_MEN = 'skincare-for-men';
export const SKINCARE_FOR_MEN_MARKETING_SAFE =
  'skincare-for-men-marketing-safe';
export const RETAIL_OFFER = 'retail-offer';

export const ANTI_AGING_CODE = '4i0';
export const ANTI_AGING_MARKETING_SAFE_CODE = '4i0g';
export const CORE_MARKETING_SAFE_CODE = '1i0g';
export const CORE_CODE = '1i0n';
export const DARK_SPOT_FORMULA_CODE = '4i1n';
export const DARK_SPOT_FORMULA_MARKETING_SAFE_CODE = '4i1g';
export const MALE_CODE = '1i1n';
export const MALE_MARKETING_SAFE_CODE = '1i1yt';
export const NEUTRAL_CODE = '1i4n';
export const NEUTRAL_MARKETING_SAFE_CODE = '1i4g';
export const MOMS_OF_TEENS_CODE = 'teen-acne-treatment';
export const MOMS_OF_TEENS_MARKETING_SAFE_CODE = 'teen-skin-treatment';
export const SKINCARE_FOR_MEN_CODE = 'skincare-for-men';
export const SKINCARE_FOR_MEN_MARKETING_SAFE_CODE = 'custom-skincare-for-men';
export const TEEN_CODE = '1i5n';
export const TRETINOIN_CODE = 'tretinoin';
export const NIACINAMIDE_CODE = 'niacinamide';
export const METRONIDAZOLE_CODE = 'metronidazole';
export const CLINDAMYCIN_CODE = 'clindamycin';
export const HYDROCORTISONE_CODE = 'hydrocortisone';
export const CUSTOM_FORMULA_CODE = 'custom-formula';
export const ROSACEA_CODE = 'rosacea';
export const ROSACEA_MARKETING_SAFE_CODE = 'rosacea-marketing-safe';
export const BRAND_RESPONSE_CODE = 'brand-response';
export const RETAIL_OFFER_CODE = 'retail-offer';

export type LandingVariantsType =
  | typeof ANTI_AGING
  | typeof ANTI_AGING_MARKETING_SAFE
  | typeof CORE
  | typeof CORE_MARKETING_SAFE
  | typeof DARK_SPOT_FORMULA
  | typeof DARK_SPOT_FORMULA_MARKETING_SAFE
  | typeof HOME
  | typeof MALE
  | typeof MALE_MARKETING_SAFE
  | typeof MOMS_OF_TEENS
  | typeof MOMS_OF_TEENS_MARKETING_SAFE
  | typeof NEUTRAL
  | typeof NEUTRAL_MARKETING_SAFE
  | typeof SKINCARE_FOR_MEN
  | typeof SKINCARE_FOR_MEN_MARKETING_SAFE
  | typeof RETAIL_OFFER;

// DO NOT ADD DISPOSABLE MARKETING CODES.
// Variant codes here are saved to the database and associated
// to a user permanently.
export type LandingVariantsCodeType =
  | typeof ANTI_AGING_CODE
  | typeof ANTI_AGING_MARKETING_SAFE_CODE
  | typeof CORE_MARKETING_SAFE_CODE
  | typeof CORE_CODE
  | typeof DARK_SPOT_FORMULA_CODE
  | typeof DARK_SPOT_FORMULA_MARKETING_SAFE_CODE
  | typeof MALE_CODE
  | typeof MALE_MARKETING_SAFE_CODE
  | typeof MOMS_OF_TEENS_CODE
  | typeof MOMS_OF_TEENS_MARKETING_SAFE_CODE
  | typeof NEUTRAL_CODE
  | typeof NEUTRAL_MARKETING_SAFE_CODE
  | typeof SKINCARE_FOR_MEN_CODE
  | typeof SKINCARE_FOR_MEN_MARKETING_SAFE_CODE
  | typeof TEEN_CODE
  | typeof TRETINOIN_CODE
  | typeof NIACINAMIDE_CODE
  | typeof METRONIDAZOLE_CODE
  | typeof CLINDAMYCIN_CODE
  | typeof HYDROCORTISONE_CODE
  | typeof CUSTOM_FORMULA_CODE
  | typeof ROSACEA_CODE
  | typeof ROSACEA_MARKETING_SAFE_CODE
  | typeof BRAND_RESPONSE_CODE
  | typeof RETAIL_OFFER_CODE;
