import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import retina from 'styles/mixins/retinaBackgroundImage';

import providersSm from './images/providersSm.jpg';
import providersSm2x from './images/providersSm@2x.jpg';
import providersLg from './images/providersLg.jpg';
import providersLg2x from './images/providersLg@2x.jpg';

export const ProvidersBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 730px;

  ${MEDIA_QUERIES.mdUp} {
    height: 640px;
    flex-direction: row;
    margin: 0 auto;
    max-width: 1250px;
  }

  ${MEDIA_QUERIES.lgUp} {
    gap: ${SPACER.x4large};
  }
`;

export const ProviderImageContainer = styled.div`
  height: 375px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${providersSm});
  ${retina(providersSm2x)};

  ${MEDIA_QUERIES.mdUp} {
    height: auto;
    flex: 50%;
    background-image: url(${providersLg});
    ${retina(providersLg2x)};
  }
`;

export const ContentContainer = styled.div`
  padding: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    flex: 50%;
    align-self: center;
  }
`;

export const headerStyles = css`
  margin-bottom: ${SPACER.small};
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.medium};
  }
`;

export const bodyStyles = css`
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    max-width: 384px;
  }
`;

export const ctaStyle = css`
  position: relative;
  padding-top: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    padding-top: ${SPACER.medium};
  }
`;
