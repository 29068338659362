import {
  COMPLETE_SET_DESCRIPTION_TRIAL_BOX,
  COMPLETE_SET_HEADER_ONE_TRIAL,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { CompleteSetProps } from '..';

export const COMPLETE_SET_MALE_MARKETING_SAFE_CONTENT: CompleteSetProps = {
  header: COMPLETE_SET_HEADER_ONE_TRIAL,
  description: COMPLETE_SET_DESCRIPTION_TRIAL_BOX,
  ctaButton: {
    buttonText: 'See if you’re eligible',
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
