import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

export const arrowButtonStyle = (theme: ThemeType) => css`
  align-items: center;
  margin: 0 ${SPACER.small};
  z-index: ${Z_SCALE.e2};
  border-color: ${theme.COLORS.primary};

  :disabled {
    background-color: ${theme.COLORS.white};
    svg path {
      fill: ${theme.COLORS.primary};
    }
  }
`;

export const arrowMobile = (
  theme: ThemeType,
  isMobileArrowVisbile: boolean,
) => css`
  display: flex;
  position: absolute;
  top: 260px;
  left: 303px;
  opacity: ${isMobileArrowVisbile ? 1 : 0};
  transition: all 0.4s;
  background-color: ${theme.COLORS.primary};

  svg path {
    fill: ${theme.COLORS.white};
  }

  :hover {
    opacity: ${isMobileArrowVisbile ? 1 : 0};
  }
`;

export const CardSlider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: ${SPACER.large};
  scroll-behavior: smooth;
  padding: 0 0 ${SPACER.large};
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 0 ${SPACER.x5large};
    scroll-padding: ${SPACER.x2large};
  }

  scrollbar-color: #${({ theme }) => theme.COLORS.primary};

  ::-webkit-scrollbar {
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.COLORS.primaryTint2};
    background: linear-gradient(
      180deg,
      transparent 0%,
      transparent 33%,
      ${({ theme }) => theme.COLORS.primaryTint2} 34%,
      ${({ theme }) => theme.COLORS.primaryTint2} 65%,
      transparent 66%,
      transparent 100%
    );
    box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.COLORS.primary};
    border-radius: 10px;
    height: 3px;
  }

  ::-webkit-scrollbar-button:start {
    display: block;
    background: transparent;
  }

  ::-webkit-scrollbar-button:end:increment {
    width: ${SPACER.xlarge};
    display: block;
    background: transparent;

    ${MEDIA_QUERIES.mdUp} {
      width: 160px;
      display: block;
      background: transparent;
    }
  }
`;

export const CardSliderContainer = styled.div`
  position: relative;
`;

export const HeaderCard = styled.div`
  display: none;
  ${MEDIA_QUERIES.mdUp} {
    scroll-snap-align: start;
    margin: auto ${SPACER.x2large};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const headerText = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.heading(theme)};
  padding: ${SPACER.large};
  width: 309px;

  ${MEDIA_QUERIES.mdUp} {
    ${TYPOGRAPHY_STYLE.display(theme)};
    padding: 0;
  }
`;

export const sectionBox = (theme: ThemeType) => css`
  background-color: ${theme.COLORS.tertiaryTint1};
`;

export const MainBox = styled.div`
  margin: 0 auto;
  padding: 0 0 ${SPACER.large};
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  overflow: visible;

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x2large} ${SPACER.x2large};
    position: relative;
  }
`;

export const SliderNavigation = styled.div`
  display: none;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -1.75rem 0 0;
  }
`;
