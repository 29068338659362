import {
  COMPLETE_SET_DESCRIPTION_GLOWING,
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { CompleteSetProps } from '..';

export const COMPLETE_SET_CORE_CONTENT: CompleteSetProps = {
  header: 'The perfect pairing for your custom prescription',
  description: COMPLETE_SET_DESCRIPTION_GLOWING,
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
