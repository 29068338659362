import styled from '@emotion/styled';
import { Typography } from 'radiance-ui';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    align-items: initial;
    max-width: 888px;
    margin-top: ${SPACER.x4large};
  }
`;

export const ListContainer = styled.div<{ isPositive?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    margin: 0 ${SPACER.small};
    width: 50%;
  }

  ${({ isPositive, theme }) =>
    isPositive &&
    `
    background-color: ${theme.COLORS.white};
    box-shadow: ${theme.BOX_SHADOWS.clickable};
  `}
`;

export const Title = styled(Typography.Title)`
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.defaultBorder};
  padding-bottom: ${SPACER.medium};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: ${SPACER.large} 0 0 0;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: ${SPACER.xlarge};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ItemText = styled.span`
  margin-left: ${SPACER.medium};
  line-height: 1.25;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;
