import styled from '@emotion/styled';
import React, { useEffect } from 'react';

import type { LandingVariantsCodeType } from 'consts/landingVariants';
import { LOCAL_COLORS } from 'styles/constants';
import captureInviteToken from 'utils/captureInviteToken';
import { captureLandingPageUrl } from 'utils/captureReferrer';
import captureVariant from 'utils/captureVariant';
import setupModuleObserver from 'utils/setupModuleObserver';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    width: 100%;
  }

  background: ${LOCAL_COLORS.surfaceLight};
`;

export const LandingPageWrapper = ({
  children,
  variantCode,
}: {
  children: React.ReactNode;
  variantCode?: LandingVariantsCodeType;
}) => {
  useEffect(() => {
    captureInviteToken();
    captureLandingPageUrl();

    // This will create a cookie with the code which the laravel
    // app will save and associate with the user permanently.
    // DO NOT ADD DISPOSABLE MARKETING CODES.
    if (variantCode) {
      captureVariant(variantCode);
    }

    setupModuleObserver();
  }, []);

  return <Container>{children}</Container>;
};
