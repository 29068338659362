import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import type { ProvidersOldProps } from '..';

export const PROVIDERS_OLD_ANTI_AGING_CONTENT: ProvidersOldProps = {
  title: 'Real dermatology providers take your skin personally',
  text: 'Forget “custom” algorithms. You’re matched with a licensed dermatology provider who gets to know your skin. They’ll ask questions, check in, and stick by you.',
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
};
