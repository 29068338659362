import { parseQueryString } from 'great-gatsby/utils';

import { COOKIE_KEYS } from 'consts/cookieKeys';
import cookie from 'utils/cookie';

const captureInviteToken = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const { location } = window;
  const queryParams = parseQueryString(location);
  const token = queryParams.t;

  if (token) {
    cookie.set(COOKIE_KEYS.invite, token);
  }
};

export default captureInviteToken;
