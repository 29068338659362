import React, { useState } from 'react';
import { Button } from 'radiance-ui';
import {
  CheckmarkIcon,
  CrossIcon,
  SatisfiedEmoji,
  UnsatisfiedEmoji,
} from 'radiance-ui/lib/icons';

import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import { CtaButton } from 'components/global/CtaButton';

import {
  CardContainer,
  CardContent,
  imageStyle,
  Question,
  quizButton,
  Response,
  Spacer,
} from './QuizCard.style';
import type { QuizCardType } from '..';

export type QuizCardProps = {
  card: QuizCardType;
  isEndCard: boolean;
  onChange: () => void;
};

export const QuizCard = ({ card, isEndCard, onChange }: QuizCardProps) => {
  const { question, response, correctAnswer, image } = card;
  const [answer, setAnswer] = useState<null | boolean>(null);

  const handleClick = (selectedAnswer: boolean) => {
    setAnswer(selectedAnswer);
    onChange();

    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      'Module Name': `Quiz`,
      'Module Element Section': question,
      'Module Element Interaction Type': 'True or False',
      'Element Content': answer,
    });
  };

  const isAnswerSelected = typeof answer === 'boolean';
  const isCorrectAnswer = answer === correctAnswer;

  return (
    <React.Fragment>
      <CardContainer isEndCard={isEndCard}>
        <div css={imageStyle(isAnswerSelected, isEndCard)}>{image}</div>
        <CardContent isEndCard={isEndCard} showResponse={isAnswerSelected}>
          <Question showResponse={isAnswerSelected}>{question}</Question>
          {isEndCard ? (
            <CtaButton
              buttonText={CTA_UNLOCK_YOUR_OFFER}
              disclaimerText={DISCLAIMER_CONSULTATION_CANCEL}
              module="quiz"
            />
          ) : (
            <React.Fragment>
              <Button
                buttonType="secondary"
                onClick={() => handleClick(true)}
                css={() => quizButton(answer === false)}
              >
                {answer === true && (
                  <React.Fragment>
                    {isCorrectAnswer ? (
                      <CheckmarkIcon width={16} height={16} />
                    ) : (
                      <CrossIcon width={16} height={16} />
                    )}
                  </React.Fragment>
                )}
                True
              </Button>

              <Button
                buttonType="secondary"
                onClick={() => handleClick(false)}
                css={() => quizButton(answer === true)}
              >
                {answer === false && (
                  <React.Fragment>
                    {isCorrectAnswer ? (
                      <CheckmarkIcon width={16} height={16} />
                    ) : (
                      <CrossIcon width={16} height={16} />
                    )}
                  </React.Fragment>
                )}
                False
              </Button>
            </React.Fragment>
          )}
          <Response showResponse={isAnswerSelected}>
            <p>
              {isCorrectAnswer ? (
                <SatisfiedEmoji width={35} height={35} />
              ) : (
                <UnsatisfiedEmoji width={35} height={35} />
              )}
              {isCorrectAnswer ? 'Correct! ' : 'Nice try! '} {response}
            </p>
          </Response>
        </CardContent>
      </CardContainer>
      {isEndCard && <Spacer />}
    </React.Fragment>
  );
};
