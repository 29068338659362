import React from 'react';

import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

import { StrikeThrough } from '../ComparisonChart.style';
import type { ComparisonChartProps } from '..';

export const COMPARISON_CHART_CORE_CONTENT: ComparisonChartProps = {
  header: (
    <React.Fragment>
      One-size-fits <StrikeThrough>all</StrikeThrough> you skincare
    </React.Fragment>
  ),
  subHeader: (
    <React.Fragment>
      Shopping for skincare at the drugstore is frustrating. Buy it, try it,
      toss it, repeat. Sound familiar?
      <br css={desktopOnly()} /> Get skincare made to fit—with some of the top
      acne-busting ingredients prescribed by dermatologists nationwide.
    </React.Fragment>
  ),
  chart: {
    negative: {
      title: 'Off-the-shelf acne products',
      list: [
        'One-size-fits-all (does it ever?)',
        'No prescription ingredients',
        'You’re on your own',
      ],
    },
    positive: {
      title: 'Curology',
      list: [
        'Customized for your skin',
        'Prescription ingredients, picked for you',
        'Expert support from a real provider',
      ],
    },
  },
};
