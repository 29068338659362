import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const OuterContainer = styled.div`
  padding: ${SPACER.x3large} ${SPACER.large};
  background-color: ${({ theme }) => theme.COLORS.tertiaryTint3};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x5large} ${SPACER.large};
  }
`;

export const InnerContainer = styled.div`
  max-width: ${GRID.xlContentMaxWidth};
  margin: 0 auto;
`;

export const header = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  margin-bottom: ${SPACER.large};
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.x2large};
    text-align: center;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    margin-bottom: ${SPACER.x5large};
  }
`;

export const ProductContainer = styled.div`
  max-width: 325px;
  margin-bottom: ${SPACER.large};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${MEDIA_QUERIES.mdUp} {
    max-width: 345px;
    margin-right: 125px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const ProductDetails = styled.div`
  margin-top: ${SPACER.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;

  ${MEDIA_QUERIES.lgUp} {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

export const productName = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.title(theme)};
  line-height: 1;
`;

export const productPrice = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
`;

export const productDescription = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.caption(theme)};
  color: ${theme.COLORS.primary};
`;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
`;
