import React from 'react';

import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';

import {
  ProvidersBox,
  ProviderImageContainer,
  ContentContainer,
  ctaStyle,
  headerStyles,
  bodyStyles,
} from './ProvidersOld.style';

export type ProvidersOldProps = {
  title: string;
  text: string;
  ctaButton?: CtaButtonProps;
};

const moduleName = 'providersOld';

export const ProvidersOld = ({ title, text, ctaButton }: ProvidersOldProps) => (
  <ProvidersBox data-module={moduleName}>
    <ProviderImageContainer>&nbsp;</ProviderImageContainer>
    <ContentContainer>
      <h2 css={headerStyles}>{title}</h2>
      <p css={bodyStyles}>{text}</p>
      {ctaButton && (
        <CtaButton {...ctaButton} module={moduleName} css={ctaStyle} />
      )}
    </ContentContainer>
  </ProvidersBox>
);
