/** TODO: UPDATE TO MATCH RADIANCE SITE FIGMA FILE
 *
 * Update component to use src/styles/global-typography.tsx elements
 * and
 * Match spacing and styles to Radiance Site > Module Components, Figma file
 *
 * https://www.figma.com/file/I5fG21Kyb02JocAknlpbp4/Radiance-Site?node-id=1%3A490
 *
 * Mark complete here:
 * https://www.notion.so/curology/Radiance-Site-Figma-Updates-c8869d7b9a4346d980407d602c41086d
 */
import React from 'react';

import { Chart } from './components/Chart';
import {
  Container,
  Content,
  Header,
  Intro,
  SubHeader,
} from './ComparisonChart.style';

const moduleName = 'comparisonChart';

export interface ComparisonChartProps {
  backgroundColor?: string;
  header: React.ReactNode;
  subHeader: React.ReactNode;
  chart: {
    negative: {
      title: string;
      list: string[];
    };
    positive: {
      title: string;
      list: string[];
    };
  };
}

export const ComparisonChart = ({
  backgroundColor = '',
  header,
  subHeader,
  chart,
}: ComparisonChartProps) => (
  <Container data-module={moduleName} backgroundColor={backgroundColor}>
    <Content>
      <Intro>
        <Header>{header}</Header>
        <SubHeader>{subHeader}</SubHeader>
      </Intro>
      <Chart data={chart} />
    </Content>
  </Container>
);
