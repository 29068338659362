import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CTA_UNLOCK_YOUR_TEENS_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
  LANDING_HERO_DESCRIPTION_TEEN,
} from 'consts/copy';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

import slide1 from '../images/slide1.jpg';
import slide1At2x from '../images/slide1@2x.jpg';
import slide2 from '../images/slide2.jpg';
import slide2At2x from '../images/slide2@2x.jpg';
import slide3 from '../images/slide3.jpg';
import slide3At2x from '../images/slide3@2x.jpg';
import slide4 from '../images/slide4.jpg';
import slide4At2x from '../images/slide4@2x.jpg';
import slide5 from '../images/slide5.jpg';
import slide5At2x from '../images/slide5@2x.jpg';
import slide6 from '../images/slide6.jpg';
import slide6At2x from '../images/slide6@2x.jpg';
import type { LandingHeaderMomsOfTeensProps } from '..';

export const LANDING_HEADER_MOMS_OF_TEENS_CONTENT: LandingHeaderMomsOfTeensProps =
  {
    title: (
      <React.Fragment>
        Clarity for you. <br css={desktopOnly()} />
        <span>And your teen’s skin.</span>
      </React.Fragment>
    ),
    ctaButton: {
      buttonText: CTA_UNLOCK_YOUR_TEENS_OFFER,
      disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
    },
    description: LANDING_HERO_DESCRIPTION_TEEN,
    images: {
      desktop: {
        alt: 'College of Curology patient with mom and Curology products',
        src: [
          { '1x': slide1, '2x': slide1At2x },
          { '1x': slide2, '2x': slide2At2x },
          { '1x': slide3, '2x': slide3At2x },
          { '1x': slide4, '2x': slide4At2x },
          { '1x': slide5, '2x': slide5At2x },
          { '1x': slide6, '2x': slide6At2x },
        ],
      },
      mobile: [
        <StaticImage
          src="../images/slide1Mobile@2x.jpg"
          alt="Curology patient and her mom"
          loading="eager"
        />,
        <StaticImage
          src="../images/slide2Mobile@2x.jpg"
          alt="Curology custom formula"
          loading="eager"
        />,
        <StaticImage
          src="../images/slide3Mobile@2x.jpg"
          alt="Curology patient and his mom"
          loading="eager"
        />,
        <StaticImage
          src="../images/slide4Mobile@2x.jpg"
          alt="Curology cleanser and moisturizer"
          loading="eager"
        />,
        <StaticImage
          src="../images/slide5Mobile@2x.jpg"
          alt="Curology patient and her mom"
          loading="eager"
        />,
      ],
    },
  };
