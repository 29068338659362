import React from 'react';

import type { WelcomeProps } from '..';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const WELCOME_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT: WelcomeProps = {
  title: (
    <>
      A letter from Dr. Nancy Satur, mom <br css={desktopOnly()} /> and
      co-founder
    </>
  ),
  body: (
    <>
      <p>
        As a mom, teen skin issues have always been near to my heart. My sons
        and I founded Curology on the belief that effective treatment should be
        both affordable and accessible.
      </p>
      <br />
      <p>
        Our approach is to treat patients like family. Our providers are here to
        offer one-on-one guidance, recommendations and support throughout your
        teen’s entire journey. We’re excited to welcome you to our community.
      </p>
    </>
  ),
};
