import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  padding: ${SPACER.x5large} 0;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.COLORS.background};

  ${MEDIA_QUERIES.mdUp} {
    padding: 5.5rem 0 6.5rem;
  }
`;

export const Content = styled.div`
  max-width: 944px;
  margin: 0 auto;
  padding: 0 ${SPACER.small};

  ${MEDIA_QUERIES.xsUp} {
    padding: 0 ${SPACER.large};
  }
`;

export const Intro = styled.div`
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    text-align: center;
  }
`;

export const Header = styled.h3`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    line-height: 2;
  }
`;

export const StrikeThrough = styled.span`
  text-decoration: line-through;
`;

export const SubHeader = styled.p`
  padding-top: ${SPACER.small};
`;
