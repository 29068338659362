import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CTA_UNLOCK_YOUR_TEENS_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import type { ProductsProps } from '..';

export const PRODUCTS_MOMS_OF_TEENS_CONTENT: ProductsProps = {
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_TEENS_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
  title: 'Popular products to add to your teen’s routine',
  products: [
    {
      name: 'Acne body wash',
      price: '+$9/mo',
      description: 'Treats and helps prevent body acne',
      image: (
        <StaticImage src="../images/acneBodyWash@2x.jpg" alt="Acne body wash" />
      ),
    },
    {
      name: 'Emergency spot patches',
      price: '+$2.48/mo',
      description: 'Fast-acting patch for surprising spots',
      image: (
        <StaticImage
          src="../images/emergencySpotPatch@2x.jpg"
          alt="Emergency spot patches"
        />
      ),
    },
  ],
};
