import React from 'react';

import type { ComparisonChartProps } from '..';

export const COMPARISON_CHART_MOMS_OF_TEENS_CONTENT: ComparisonChartProps = {
  header: (
    <React.Fragment>
      Skincare <span css={{ textDecoration: 'underline' }}>is not</span>{' '}
      one-size fits all
    </React.Fragment>
  ),
  subHeader:
    'Buy it, try it, toss it, repeat. Sound familiar? Shopping for drugstore solutions is often frustrating — and can get expensive, too.',
  chart: {
    negative: {
      title: 'Off-the-shelf acne products',
      list: [
        'One-size-fits-all (does it ever?)',
        'No prescription ingredients',
        'You’re on your own',
      ],
    },
    positive: {
      title: 'Curology',
      list: [
        'Customized for your skin',
        'Prescription ingredients, picked for you',
        'Expert support from a real provider',
      ],
    },
  },
};
