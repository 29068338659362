import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const CardContainer = styled.div<{ isEndCard: boolean }>`
  width: 300px;
  height: 615px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  margin-left: ${SPACER.large};
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  scroll-snap-align: start;

  ${MEDIA_QUERIES.mdUp} {
    height: 659px;
    width: 317px;
  }
`;

export const CardContent = styled.div<{
  isEndCard: boolean;
  showResponse: boolean;
}>`
  padding: ${SPACER.x2large} ${SPACER.large} ${SPACER.large};
  padding-top: ${({ showResponse }) => showResponse && SPACER.large};
  display: flex;
  flex-direction: column;
  background-color: ${({ isEndCard, theme }) =>
    !isEndCard && theme.COLORS.white};
  position: absolute;
  bottom: 0;
  min-height: 350px;
  transition: 0.4s all;
`;

export const imageStyle = (showResponse: boolean, isEndCard: boolean) => css`
  width: 100%;
  height: ${showResponse ? `35` : `50`}%;
  ${isEndCard && `height: 100%;`}
  transition: all 0.4s ease-out;
  .gatsby-image-wrapper {
    height: 100%;
    img {
      object-fit: cover;
      object-position: ${isEndCard ? `center 80%` : `center top`};
      transition: all 0.4s ease-out;
    }
  }
`;

export const Question = styled.div<{ showResponse: boolean }>`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  min-height: 96px;
  transition: all 0.4s;
  margin-bottom: ${({ showResponse }) =>
    showResponse ? SPACER.medium : SPACER.xlarge};
`;

export const Response = styled.div<{ showResponse: boolean }>`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  font-size: 16px;
  line-height: 28px;
  display: flex;
  height: ${({ showResponse }) => (showResponse ? '140px' : '0px')};
  opacity: ${({ showResponse }) => (showResponse ? 1 : 0)};
  transition: 0.4s all;

  ${MEDIA_QUERIES.mdUp} {
    margin: 0 0 ${SPACER.large};
    height: ${({ showResponse }) => (showResponse ? '120px' : '0px')};
  }

  svg {
    display: inline-block;
    margin: 0 0 -12px -${SPACER.xsmall};
  }
`;

export const Spacer = styled.div`
  width: ${SPACER.large};
  flex: 0 0 auto;
`;

export const quizButton = (
  isTrueClicked?: boolean,
  isFalseClicked?: boolean,
) => css`
  width: 100%;
  opacity: ${isTrueClicked || isFalseClicked ? 0.4 : 1};
  margin-bottom: ${SPACER.medium};

  div span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: ${SPACER.medium};
  }
`;
