import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaButton } from 'components/global/CtaButton';

import {
  BackgroundWrapper,
  MainBox,
  InnerBox,
  Header,
  Description,
  DesktopTextContainer,
  completeSetMobileStyles,
  completeSetDesktopStyles,
  desktopBackgroundStyles,
  buttonMarginStyles,
} from './CompleteSet.style';

export interface CompleteSetProps {
  header: React.ReactNode;
  description: string;
  ctaButton: {
    buttonText: string;
    disclaimerText: string;
  };
  overlapBackgroundColor?: string;
}

const moduleName = 'completeSet';

export const CompleteSet = ({
  header,
  description,
  ctaButton,
  overlapBackgroundColor = 'transparent',
}: CompleteSetProps) => {
  return (
    <BackgroundWrapper overlapBackgroundColor={overlapBackgroundColor}>
      <MainBox data-module={moduleName}>
        <StaticImage
          src="./images/desktopBackground@2x.png"
          alt="desktop background"
          css={desktopBackgroundStyles}
        />
        <InnerBox>
          <DesktopTextContainer>
            <Header>{header}</Header>
            <Description>{description}</Description>
            <StaticImage
              src="./images/completeSetMobile@2x.png"
              alt="complete set"
              width={718}
              height={588}
              css={completeSetMobileStyles}
            />
            <CtaButton
              {...ctaButton}
              color="white"
              module={moduleName}
              css={buttonMarginStyles}
            />
          </DesktopTextContainer>
          <StaticImage
            src="./images/completeSetDesktop@2x.png"
            alt="complete set"
            width={615}
            height={514}
            css={completeSetDesktopStyles}
          />
        </InnerBox>
      </MainBox>
    </BackgroundWrapper>
  );
};
