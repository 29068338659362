const retina = (image, size) => `
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${image});
    background-size: ${size};
  }
`;

export const retinaBackground = (
  backImageValue,
  backSizeValue = 'inherit',
  backPositionValue = 'inherit',
) => `
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: ${backImageValue};
    background-size: ${backSizeValue};
    background-position: ${backPositionValue};
  }
`;

export default retina;
