import React from 'react';

import type { WelcomeProps } from '..';

export const WELCOME_MOMS_OF_TEENS_CONTENT: WelcomeProps = {
  title:
    'A letter from Dr. Nancy Satur, Mom, Medical Director and Curology co-founder',
  body: (
    <>
      <p>
        As a mom, teen skin issues have always been near to my heart. My sons
        and I founded Curology on the belief that effective treatment should be
        both affordable and accessible.
      </p>
      <br />
      <p>
        Our approach is to treat patients like family. Our licensed medical
        providers are here to offer one-on-one guidance, recommendations and
        support throughout your teen’s entire journey. We’re excited to welcome
        you to our community.
      </p>
    </>
  ),
};
