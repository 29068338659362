import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import { LOCAL_EASE_FUNCTION } from 'styles/constants';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';
import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';

import {
  OuterContainer,
  InnerContainer,
  headerStyles,
  descriptionStyles,
  TextContainer,
  CarouselSlide,
  MainCarouselBox,
} from './LandingHeaderMomsOfTeens.style';

export type LandingHeaderMomsOfTeensProps = {
  title: React.ReactNode;
  ctaButton: CtaButtonProps;
  description: string;
  images: {
    desktop: {
      alt: string;
      src: { '1x': string; '2x': string }[];
    };
    mobile: JSX.Element[];
  };
};

const CAROUSEL_SETTINGS = {
  accessibility: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  easing: LOCAL_EASE_FUNCTION,
  infinite: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  slidesToShow: 1,
  speed: 1000,
};

const moduleName = 'landingHeaderMomsOfTeens';

export const LandingHeaderMomsOfTeens = ({
  title,
  ctaButton,
  description,
  images,
}: LandingHeaderMomsOfTeensProps) => {
  const [picIndex, setPicIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPicIndex((index) => (index + 1) % images.desktop.src.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <OuterContainer data-module={moduleName}>
      <InnerContainer>
        <TextContainer>
          <h1 css={headerStyles}>{title}</h1>
          <p css={descriptionStyles}>{description}</p>
          <CtaButton {...ctaButton} module={moduleName} />
        </TextContainer>
        <img
          src={images.desktop.src[picIndex]['1x']}
          srcSet={`${images.desktop.src[picIndex]['2x']} 2x`}
          alt={images.desktop.alt}
          css={desktopOnly()}
        />
      </InnerContainer>
      <MainCarouselBox>
        <Slider {...CAROUSEL_SETTINGS}>
          {images.mobile.map((image, index) => (
            <CarouselSlide key={index}>{image}</CarouselSlide>
          ))}
        </Slider>
      </MainCarouselBox>
    </OuterContainer>
  );
};
