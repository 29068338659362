import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { mobileOnly } from 'styles/mixins/displayMobileDesktop';
import reactSlickStyles from 'styles/reactSlick';
import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

export const OuterContainer = styled.div`
  background: ${({ theme }) => theme.COLORS.background};
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;
  padding: ${SPACER.x2large} ${SPACER.large} 0;
  position: relative;
  overflow: hidden;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    height: 480px;
    display: flex;
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
    height: 640px;
    display: flex;
  }
`;

export const headerStyles = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.display(theme)};
  font-family: ${theme.FONTS.headerFont};
  font-weight: ${theme.TYPOGRAPHY.fontWeight.normal};
  text-align: left;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 50px;
    line-height: 65px;
  }

  span {
    font-family: ${theme.FONTS.baseFont};
    font-weight: ${theme.TYPOGRAPHY.fontWeight.bold};
  }
`;

export const descriptionStyles = (theme: ThemeType) => css`
  margin-top: ${SPACER.small};
  color: ${theme.COLORS.primaryTint1};
  margin-bottom: ${SPACER.x2large};

  ${MEDIA_QUERIES.mdUp} {
    margin-top: ${SPACER.medium};
    max-width: 375px;
  }
`;

export const TextContainer = styled.div`
  padding-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    position: relative;
    z-index: 2;
    width: 50%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 ${SPACER.x5large};
  }
`;

export const MainCarouselBox = styled.div`
  ${mobileOnly()};
  ${reactSlickStyles};
  line-height: 0;
`;

export const CarouselSlide = styled.div`
  img {
    width: 100%;
  }
`;
