import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  Container,
  ImageContainer,
  NancyImageContainer,
  FamilyImageContainer,
  TextContainer,
  headerStyles,
  imageLabelStyles,
} from './Welcome.style';

export type WelcomeProps = {
  title: React.ReactNode;
  body: React.ReactNode;
};

export const Welcome = ({ title, body }: WelcomeProps) => (
  <Container data-module="welcome">
    <ImageContainer>
      <NancyImageContainer>
        <StaticImage src="./images/nancyDesktop@2x.jpg" alt="Nancy" />
      </NancyImageContainer>
      <div css={imageLabelStyles}>With the boys, 1996</div>
      <FamilyImageContainer>
        <StaticImage
          src="./images/familyDesktop@2x.png"
          alt="Nancy and her sons"
        />
      </FamilyImageContainer>
    </ImageContainer>
    <TextContainer>
      <h3 css={headerStyles}>{title}</h3>
      {body}
      <br />
      <p>Warmly,</p>
      <StaticImage
        src="./images/signatureDesktop@2x.png"
        alt="Nancy's signature"
        width={259}
      />
      <p>Nancy Satur, MD</p>
    </TextContainer>
  </Container>
);
