import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import retina from 'styles/mixins/retinaBackgroundImage';
import {
  LOCAL_CONTENT_MAX_WIDTH,
  LOCAL_MEDIA_QUERIES,
  LOCAL_SPACING,
} from 'styles/constants';
import { mobileOnly, desktopOnly } from 'styles/mixins/displayMobileDesktop';

import mobileBackground from './images/mobileBackground.jpg';
import mobileBackground2x from './images/mobileBackground@2x.jpg';

export const BackgroundWrapper = styled.div<{
  overlapBackgroundColor?: string;
}>`
  overflow: hidden;
  ${MEDIA_QUERIES.mdUp} {
    padding-top: 125px;
    background: ${({ overlapBackgroundColor }) => overlapBackgroundColor};
  }
`;

export const MainBox = styled.div`
  margin: 0 auto;
  background-color: ${({ theme }) => theme.COLORS.primary};
  background-image: url(${mobileBackground});
  background-repeat: no-repeat;
  background-size: cover;
  ${retina(mobileBackground2x, 'cover')};

  ${MEDIA_QUERIES.mdUp} {
    background-image: none;
    position: relative;
    height: 620px;
  }
`;

export const InnerBox = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;
  padding: ${SPACER.x5large} ${SPACER.large} ${SPACER.x3large};
  min-height: 764px;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${SPACER.medium} 0;
    min-height: auto;
    position: relative;
    z-index: ${Z_SCALE.e3};
    height: 620px;
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: 0 60px 0;
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: 0 90px 0 ${LOCAL_SPACING.desktopMainPadding};
  }
`;

export const desktopBackgroundStyles = css`
  ${desktopOnly()};
  position: absolute;
  top: -130px;
  bottom: 0;
  left: 35%;
  margin-left: auto;
  z-index: ${Z_SCALE.e3};
  height: 750px;
  width: 1591px;
  min-width: 1591px;

  ${MEDIA_QUERIES.lgUp} {
    left: 30%;
  }

  ${MEDIA_QUERIES.xxlUp} {
    left: 33%;
  }

  ${LOCAL_MEDIA_QUERIES.minWidth1920} {
    left: 38%;
  }

  ${LOCAL_MEDIA_QUERIES.minWidth2560} {
    left: 41%;
  }
`;

export const Header = styled.h2`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};
  color: ${({ theme }) => theme.COLORS.white};
`;

export const Description = styled.p`
  margin-top: ${SPACER.medium};
  color: ${({ theme }) => theme.COLORS.border};
`;

export const DesktopTextContainer = styled.div`
  ${MEDIA_QUERIES.mdUp} {
    max-width: 355px;
  }
`;

export const completeSetMobileStyles = css`
  ${mobileOnly()};
  margin-top: ${SPACER.x5large};
  width: 100%;
`;

export const completeSetDesktopStyles = css`
  ${desktopOnly()};
  width: 434px;
  margin-left: ${SPACER.medium};
  position: relative;
  z-index: ${Z_SCALE.e3};

  ${MEDIA_QUERIES.lgUp} {
    margin-left: 0;
    width: 515px;
  }

  ${MEDIA_QUERIES.xlUp} {
    width: 615px;
    margin-right: 15px;
  }
`;

export const buttonMarginStyles = css`
  margin-top: ${SPACER.x2large};

  ${MEDIA_QUERIES.mdUp} {
    margin-top: ${SPACER.xlarge};
  }
`;
