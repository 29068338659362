import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const Container = styled.div`
  max-width: ${GRID.xlContentMaxWidth};

  margin: 0 auto;
  padding: ${SPACER.x4large} ${SPACER.xlarge};
  align-items: center;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.mdUp} {
    width: 50%;
    margin-right: 4.5rem;
  }
`;

export const headerStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  margin-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 465px;
  }
`;

export const TextContainer = styled.div`
  ${MEDIA_QUERIES.mdUp} {
    width: 50%;
  }
`;

export const NancyImageContainer = styled.div`
  flex-shrink: 0;
  margin-bottom: ${SPACER.medium};

  img {
    width: 100%;
  }

  ${MEDIA_QUERIES.mdUp} {
    width: 70%;
  }
`;

export const imageLabelStyles = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.label(theme)};
  margin-right: 62%;
  text-align: right;

  ${MEDIA_QUERIES.mdUp} {
    margin-right: 53%;
  }
`;

export const FamilyImageContainer = styled.div`
  width: 72%;
  flex-shrink: 0;
  display: flex;
  margin: -45% -6% ${SPACER.xlarge} auto;

  img {
    width: 100%;
    height: 100%;
  }

  ${MEDIA_QUERIES.mdUp} {
    width: 55%;
    margin-top: -38%;
    margin-right: 0;
    margin-bottom: 0;
  }
`;
